import React from 'react'
import Client from './Client'
import yoramprofile from '../images/yoramprofile.jpeg'
import yoramlogo from '../images/yoramlogo.png'
import maxemslogo from '../images/maxemslogo.png'
import maxemsprofile from '../images/maxemsprofile.png'
import ilanalogo from '../images/ilanalogo.png'
import ilanaprofile from '../images/ilanaprofile.png'
import landwerlogo from '../images/landwerlogo.png'
import landwerprofile from '../images/landwerprofile.png'


export default function About() {
  return (
    <div dir='rtl' id='about'>
      <h2 className='aboutTitle'>מה הלקוחות שלנו אומרים</h2>
      <div className='grid-layout'>
      <Client blogo={maxemslogo} profile={maxemsprofile} name='גלעד יוסף - MAXEMS' text='מאז שהתחלנו לעבוד עם צוות CDM, העסק שלנו קיבל דחיפה משמעותית קדימה. הסרטונים והפוסטים שהם יוצרים כדי להציג את שיטת EMS שלנו הם איכותיים ומושכים. הקמפיינים הממומנים שלהם הגבירו את הנראות שלנו והביאו לקוחות חדשים שמתלהבים לנסות את ה-EMS. אנחנו ממליצים על השירותים שלהם - הם יודעים איך להביא תוצאות!'/>
      <Client blogo={landwerlogo} profile={landwerprofile} name='בטי מעין - לנדוור' text='ב-CDM מצאנו לא רק צוות שיווק, אלא שותפים אמיתיים להצלחה שלנו. הם מנהלים את האינסטגרם, הפייסבוק והטיקטוק שלנו בשני סניפים המרכזיים באשקלון, וכל פוסט וסטורי שהם יוצרים הוא בול פגיעה - הם מצליחים לתפוס את האווירה המיוחדת שלנו בצורה יוצאת דופן. הקמפיינים בתשלום שלהם לא רק הגדילו את מספר הלקוחות, אלא גם העלו את רמת המעורבות שלנו למקומות חדשים. היצירתיות והמקצועיות של CDM הופכים אותם לבחירה המושלמת עבורנו!'/>
      <Client blogo={yoramlogo} profile={yoramprofile} name='יורם גניש - משפחה מיוחדת במועצה' text='עבדתי עם צוות CDM במהלך מערכת הבחירות העירוניות שלנו, והחוויה הייתה יוצאת דופן. הם הצליחו להביא את המסר שלנו למען אנשים עם צרכים מיוחדים ומוגבלויות ללב של הציבור בצורה מקצועית ומדויקת. למרות שלא זכינו, התקדמנו הרבה יותר מבפעם הקודמת, ואין ספק שזה בזכות העבודה הקשה והמחויבות שלהם. אם אתם מחפשים צוות שיודע איך להניע קמפיין בצורה אפקטיבית ואמפתית, אני ממליץ בחום על CDM.'/>
      <Client blogo={ilanalogo} profile={ilanaprofile} name='אילנה כהן - תופרת' text='העבודה עם צוות הצילום והשיווק של CDM הייתה חוויה נהדרת! הם ארגנו לנו יום צילומים מקצועי ושיווק ממומן שהביא הרבה פניות חדשות. הם הקשיבו לצרכים שלנו והיו אמינים לאורך כל הדרך. אם אתם מאמינים בעסק שלכם ולא בטוחים איך להתחיל בשיווק דיגיטלי, CDM הם האנשים שלכם.'/>
      </div>
    </div>
  )
}
