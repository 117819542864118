import React from 'react'
import tlogo from '../images/truelogo.png'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
<footer className="footer">
      <div className="logo-container">
        <img src={tlogo} alt="CDM Company Logo" className="footer-logo" />
      </div>
      <div className="impact-statement">
        <h2 dir='rtl'>השקעה בנוכחות דיגיטלית היא השקעה בעתיד – עדיף להתחיל עכשיו!
        </h2>
      </div>
      <div dir='rtl' className="footer-content">
        {/* <div className="footer-section">
          <h3>תעקבו אחרינו</h3>
          <ul>
            <li>Instagram</li>
            <li>Twitter</li>
            <li>Facebook</li>
            <li>LinkedIn</li>
          </ul>
        </div> */}
        <div className="footer-section">
          <h3>תנאי השימוש והמדיניות שלנו
          </h3>
          <ul>
              <li><Link to="/terms-of-use">תנאי שימוש</Link></li>
              <li><Link to="/privacy-policy">מדיניות פרטיות</Link></li>
              <li><Link to="/accessibility-statement">הצהרת נגישות</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>שעות פעילות</h3>
          <ul>
            <li>ימי א' עד ה'</li>
            <li>08:00 עד 17:00</li>
            <li>צור קשר</li>
          </ul>
        </div>
      </div>
    </footer>
    )
}

export default Footer
