import React from 'react'

const Client = (props) => {
  return (
    <div className='client'>
      <div className='client-imgdiv'>
        <img className='blogo-img' src={props.blogo} alt="Client Logo" />
        <img className='profile-img' src={props.profile} alt="Client Profile" />
      </div>
      <div>
      <h3> {props.name} </h3>
      <p> {props.text} </p>
      </div>
    </div>
  )
}

export default Client
