import React from "react";
import graphs from '../images/graphs.png'

function Header() {
    return (
        <div id="main">
            <div className="name">
                 <h2>השג את מטרות השיווק של</h2>
                 <h1>!העסק שלך</h1>
                 <p className="details" dir="rtl">בחר בפתרון המוביל לקידום עסקים עם קמפיינים חדשניים וניהול מקצועי של מדיה חברתית. צוות המומחים שלנו כאן כדי להפתיע אותך עם תוצאות מרשימות שיגרמו לעסק שלך לבלוט ולמשוך לקוחות חדשים.</p>
                 <div className="header-btns">
                        <a type="button" href="#contact" className="header-btn">קבעו פגישה</a>
                 </div>
            </div>
            <div>
                <img className="graphs" src={graphs} alt="Graphs showing uptrending in sells" />
            </div>
        </div>
    )
}

export default Header;