import React,{useState} from "react";
import logo from '../images/truelogo.png'
import { HashLink as Link } from 'react-router-hash-link';



function Navbar() {

    const [nav,setnav] = useState(false);

    const changeBackground = () =>{
        if (window.scrollY >= 15) {
            setnav(true);
        }
        else {
            setnav(false);
        }
    }
    window.addEventListener('scroll',changeBackground);

    const closeMobileMenu = () => {
        const checkbox = document.getElementById('check');
        if (checkbox) {
            checkbox.checked = false;
        }
    }

    return(
        <>
        <nav className={nav ? "nav active" : "nav"}>
            <Link to='/#main' className='logo' smooth={true} duration={1000}>
                <img src={logo} alt='CDM company logo'/>
            </Link>
            <input className="menu-btn" type="checkbox" id="menu-btn"/>
                <label className="menu-icon" for='menu-btn'>
                    <span className="nav-icon"></span>
                </label>
                <ul className="menu">
                    <li><Link to="/#main" smooth={true} duration={1000}>בית</Link></li>
                    <li><Link to="/#features" smooth={true} duration={1000}>קצת עלינו</Link></li>
                    <li><Link to="/#presentaion" smooth={true} duration={1000}>פתרונות</Link></li>
                    <li><Link to="/#about" smooth={true} duration={1000}>פרויקטים</Link></li>
                    <li><Link to="/#contact" smooth={true} duration={1000}>צרו קשר</Link></li>
                </ul>
        </nav>
        <nav className="nav-mobile">
            <Link to="/#main" className='logo' smooth={true} duration={1000}>
                <img src={logo} alt='CDM company logo'/>
            </Link>
            <input type="checkbox" id="check"/>
            <label for="check" class="checkbtn">
                <i class="fas fa-bars"></i>
             </label>
                <ul className="mobile-ul">
                    <li><Link to="/#main" smooth={true} duration={1000} onClick={closeMobileMenu}>בית</Link></li>
                    <li><Link to="/#features" smooth={true} duration={1000} onClick={closeMobileMenu}>קצת עלינו</Link></li>
                    <li><Link to="/#presentaion" smooth={true} duration={1000} onClick={closeMobileMenu}>פתרונות</Link></li>
                    <li><Link to="/#about" smooth={true} duration={1000} onClick={closeMobileMenu}>פרויקטים</Link></li>
                    <li><Link to="/#contact" smooth={true} duration={1000} onClick={closeMobileMenu}>צרו קשר</Link></li>
                </ul>
            </nav>
        </>
    )
}

export default Navbar;