import React from 'react'
import Featurebox from './Featurebox';
import fimage1 from '../images/1.svg'
import fimage2 from '../images/2.svg'
import fimage3 from '../images/3.svg'
import fimage4 from '../images/4.svg'



function Feature() {
  return (
    <div id='features'>
        <h2>שירותים מותאמים אישית לכל צורך</h2>
        <div className='a-container'>
            <Featurebox image={fimage1} title='אסטרטגיות מותאמות אישית' text='אנחנו יוצרים עבורך אסטרטגיה מותאמת אישית שתגרום לעסק שלך לבלוט. הצלחה אמיתית מתחילה כאן.'/>
            <Featurebox image={fimage2} title={['ניהול קמפיינים', <br />, 'ממוקדים']} text='אנחנו ניצור עבורך קמפיינים שמדברים ישירות ללב של הלקוחות שלך, כי אנחנו יודעים מה באמת חשוב להם.'/>
            <Featurebox image={fimage3} title='ניהול רשתות חברתיות' text='ננהל עבורך את כל הערוצים החברתיים, ניצור אינטראקציה שמרגשת ומחברת אנשים לעסק שלך.'/>
            <Featurebox image={fimage4} title='ניתוח ודיווח' text='ננתח את הנתונים שלך, ונספק דוחות ברורים שמאפשרים לך לקבל החלטות מושכלות ולהקדים את המתחרים.






'/>
        </div>
    </div>
  )
}

export default Feature
