import React from 'react';

const TermsOfUse = () => (
  <div dir='rtl' className='terms-container'>
    <h1>תנאי שימוש לאתר</h1>
    <p>תקנון השימוש באתר נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.</p>
    <h2>מבוא</h2>
    <p>אתר "CDM" (להלן: "האתר") משמש כפורטל תדמית לפעילות העסקית של CDM בתחום ניהול רשתות חברתיות, ניהול קמפיינים, שיווק דיגיטלי, שירותי צילום ועריכה, בארץ ובחו"ל. CDM מציעה מגוון שירותים מקצועיים המותאמים לצרכים של עסקים בכל סדר גודל, תוך התמקדות ביצירת ערך מוסף ללקוחותינו. השימוש באתר כפוף לתנאי השימוש המפורטים להלן. בשימושך באתר, הנך מסכים לתנאים אלו ומתחייב לפעול על פיהם.</p>
    <h2>שינויים ועדכונים</h2>
    <p>הנהלת האתר שומרת לעצמה את הזכות לעדכן ולשנות את תנאי השימוש מעת לעת, וזאת ללא התראה מוקדמת או אזכור מיוחד בערוצי האתר השונים. על המשתמשים לבדוק את תנאי השימוש באופן תקופתי על מנת להיות מודעים לשינויים.</p>
    <h2>קניין רוחני</h2>
    <p>כל התכנים באתר, לרבות אך לא רק: לוגואים, מיתוג, עיצוב האתר, קוד האתר, קבצי מדיה (גרפיקות, סרטונים, תמונות), טקסטים, קבצים להורדה וכל חומר אחר המוצג באתר, מהווים קניין רוחני בלעדי של "CDM". אין לעשות בתכנים אלו כל שימוש ללא קבלת אישור כתוב מראש מהנהלת האתר. העתקה, הפצה, שכפול, פרסום, חיקוי או עיבוד של תכנים אלו ללא אישור כתוב מהווים הפרה של זכויות הקניין הרוחני.</p>
    <h2>זמינות ושימוש באתר</h2>
    <p>הנהלת האתר שואפת לספק שירותים זמינים ללא הפרעות, אך אינה מתחייבת כי האתר יפעל בצורה תקינה בכל עת. תקלות טכניות, תחזוקה שוטפת או תקלות צד ג' עשויות לפגוע בזמינות האתר. לא יינתן פיצוי כספי או אחר בשל הפסקות או תקלות בשירותי האתר.</p>
    <h2>קישורים לאתרים חיצוניים</h2>
    <p>האתר עשוי להכיל קישורים לאתרים חיצוניים. הנהלת האתר אינה אחראית לתכנים, לאיכות או לאמינות של אתרים אלו. הכניסה לאתרים חיצוניים הינה על אחריותו הבלעדית של המשתמש.</p>
    <h2>ניהול משתמשים</h2>
    <p>הנהלת האתר שומרת לעצמה את הזכות לחסום גישה לאתר לכל משתמש, בין אם באמצעות חסימת כתובת ה-IP, כתובת ה-MACID, או בהתאם למדינת המוצא, וזאת ללא צורך בהסבר או תירוץ. הנהלת האתר תעשה כל שביכולתה להגן על פרטיות המשתמשים הרשומים, אך אינה נושאת באחריות במקרה של גישה לא מורשית למידע על ידי צד שלישי.</p>    <h2>תחום שיפוט</h2>
    <p>בכל מקרה של מחלוקת הנוגעת לשימוש באתר, מוסכם כי הדין החל הוא הדין הישראלי בלבד, וכי הסמכות הבלעדית לדון בכל מחלוקת נתונה לבתי המשפט המוסמכים בבית המשפט השלום באשקלון.</p>
    <p>אם יש לך שאלות או הערות לגבי תנאי השימוש, אנא צור קשר עם הנהלת האתר דרך עמוד יצירת הקשר.</p>
  </div>
);

export default TermsOfUse;
