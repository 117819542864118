import React from 'react';

const PrivacyPolicy = () => (
  <div dir='rtl' className='terms-container'>
    <h1>מדיניות פרטיות באתר "CDM"</h1>
    <p>תקנון השימוש באתר נכתב בלשון זכר אך האמור בו מתייחס לנשים וגברים כאחד.</p>
    <h2>מבוא</h2>
    <p>הפרטיות שלך חשובה לנו. מסמך זה מסביר כיצד אנו אוספים, משתמשים, חושפים ושומרים על המידע האישי שלך בעת שימושך באתר.</p>
    <h2>איסוף מידע</h2>
    <p>אנו אוספים מידע אישי שאתה מספק לנו במישרין, כגון שם, כתובת דוא"ל, טלפון, והודעות שאתה שולח לנו דרך עמוד יצירת הקשר. בנוסף, אנו אוספים מידע באופן אוטומטי כאשר אתה משתמש באתר, כגון כתובת IP, סוג הדפדפן, והדפים שבהם ביקרת.</p>
    <h2>שימוש במידע</h2>
    <p>אנו משתמשים במידע שנאסף על מנת לספק לך את השירותים שלנו, לשפר את חוויית המשתמש שלך, ולהתאים את התכנים המוצגים באתר לצרכים שלך. בנוסף, אנו עשויים להשתמש במידע לצרכים סטטיסטיים ואנליטיים.</p>
    <h2>שיתוף מידע</h2>
    <p>אנו לא נשתף את המידע האישי שלך עם צדדים שלישיים ללא הסכמתך, למעט במקרים הבאים:<br/>
כאשר יש צורך בכך על מנת לספק את השירותים שלנו.
כאשר אנו מחויבים על פי חוק או בצו בית משפט.
על מנת להגן על הזכויות או הרכוש שלנו או של משתמשים אחרים.
</p>
    <h2>אבטחת מידע</h2>
    <p>אנו נוקטים בצעדים מתאימים על מנת להגן על המידע האישי שלך מפני גישה לא מורשית, שינוי, חשיפה או השמדה. אנו משתמשים בטכנולוגיות אבטחה מתקדמות ובתהליכים מובנים כדי להבטיח את פרטיותך.</p>
    <h2>קבצי עוגיות (Cookies)</h2>
    <p>האתר עושה שימוש בקבצי קוקיז לצורך תפעול ושיפור חווית המשתמש, ובכדי לשמור תיעוד סטטיסטי אנונימי של גולשים, ניתוח הרגלי גלישה וניתוח נתוני השימוש באתר. המידע הנשמר באמצעות קבצי הקוקיז הינו אנונימי ואין בו פרטים מזהים. אתה יכול לבחור לחסום או למחוק את הקוקיז דרך הגדרות הדפדפן שלך, אך פעולה זו עשויה להשפיע על חוויית השימוש באתר.</p>
    <h2>שינוי מדיניות הפרטיות</h2>
    <p>אנו שומרים לעצמנו את הזכות לשנות את מדיניות הפרטיות הזו מעת לעת. השינויים יפורסמו באתר, ואנו נמליץ לך לבדוק את המדיניות באופן תקופתי על מנת להיות מודע לשינויים.</p>
    <h2>יצירת קשר</h2>
    <p>אם יש לך שאלות או הערות לגבי מדיניות הפרטיות שלנו, אנא צור קשר עם הנהלת האתר דרך עמוד יצירת הקשר.</p>
  </div>
);

export default PrivacyPolicy;
