import React from 'react';

const AccessibilityStatement = () => (
  <div dir='rtl' className='terms-container'>
    <h1>הצהרת נגישות של אתר "CDM"</h1>
    <p>אנו רואים חשיבות רבה במתן שירות שוויוני, נגיש ומקצועי לכלל לקוחותינו. בהתאם, אנו שואפים להבטיח כי אתר האינטרנט יהיה נגיש ונוח לשימוש לכלל המשתמשים ולמשתמשים בעלי מוגבלות בפרט. על כן, אנו משקיעים מאמצים ומשאבים רבים בביצוע התאמות הנגישות הנדרשות באופן שיאפשר חווית גלישה נוחה יותר עבור אנשים עם מוגבלויות.</p>
    <h2>מידע על נגישות האתר:</h2>
    <p>האתר פועל כמיטב יכולתו על מנת לעמוד בדרישות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמת נגישות לשירות), תשע"ג – 2013, לפי ת"י 5568 המבוסס על הנחיות WCAG 2.0 ברמת AA, ובכפוף לשינויים והתאמות שבוצעו במסמך התקן הישראלי.

התאמות דפדפנים מובילים: האתר מותאם לגלישה גם בעזרת מקלדת בלבד. שימוש במקש TAB יעביר את מוקד המקלדת בין עצמים שונים בעמוד ולחיצה על מקש ה-Enter תאפשר לבחור בין האפשרויות השונות. כדי לנווט אחורה יש ללחוץ על צירוף המקשים shift + tab.
כלים וטכנולוגיות מסייעות: האתר נבדק ותומך בכלים כגון קוראי מסך, תוכנות זום, ועוד. אנו מבצעים בדיקות שוטפות לוודא תאימות עם כלים אלו.
עדכוני נגישות שוטפים: אנו ממשיכים לעדכן ולשפר את הנגישות של האתר באופן שוטף.</p>
    <h2>דרכי פנייה לבקשות והצעות לשיפור בנושא נגישות:</h2>
    <p>אנו ממשיכים במאמצים לשפר את נגישות האתר כחלק ממחויבותנו לאפשר לכלל האוכלוסייה, כולל אנשים עם מוגבלויות, לקבל את השרות הטוב והנגיש ביותר. במידה וקיימות שאלות או נתקלתם בבעיות בנושא הנגישות באתר, נשמח שתעדכנו אותנו בכך ואנו נעשה כל מאמץ להשיב לכל שאלה ולטפל בכל בעיה בהקדם.</p>
    <h2>מידע אודות הסדרי נגישותו:</h2>
    <p>פרטים לפנייה:<br/>

תיאור הבעיה: תיאור הבעיה, הפעולה שניסית לבצע, קישור לעמוד בו גלשתם, סוג הדפדפן בו גלשתם וגרסתו, סוג מערכת ההפעלה, וככל שהשתמשתם בטכנולוגיה מסייעת – נא ציינו את סוג הטכנולוגיה.<br/>
זמני תגובה: אנו מתחייבים לחזור עם מענה לפניות בנושא נגישות בתוך 5 ימי עבודה.</p>
    <p>דואר אלקטרוני: cdmsocial@mail.com <br/>
    פניה באמצעות השארת פרטים באתר: cdmsocial.com</p>
    <h2>עדכון אחרון של הצהרת נגישות:</h2>
    <p>הצהרת נגישות עודכנה ביום 3.08.2024.</p>
  </div>
);

export default AccessibilityStatement;
