import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div style={{ color: 'white', textAlign: 'center', padding: '50px' }}>
    <h1>404 - Page Not Found</h1>
    <p>Sorry, the page you are looking for does not exist.</p>
    <p>
      <Link to="/">Go back to the homepage</Link>
    </p>
  </div>
);

export default NotFoundPage;
