import React from 'react'
import meeting from '../images/meeting.png'

function Offer() {
  return (
    <div id='presentaion'>
      <div className='pr-title'>
        <h2>ההצלחה שלך היא העדיפות שלנו</h2>
      </div>
      <div className='offerdiv'>
        <img className='offerimg' alt='Team meeting' src={meeting}/>
      </div>
        <div className='pr-div'>
          <p dir='rtl'><span aria-label="Listening to your goals">אנחנו מקשיבים</span> למטרות שלך ומפתחים אסטרטגיות מותאמות נתונים.</p>
          <p dir='rtl'><span aria-label="Our team excels">הצוות שלנו מצטיין</span> ביצירת תוכן מקורי וסרטונים איכותיים שמתיישרים עם החזון של המותג שלך.</p>
          <p dir='rtl'><span aria-label="With personalized service">עם שירות מותאם אישית</span> ותמיכה מתמשכת,
אנחנו נוביל את המותג שלך להצלחה יוצאת דופן ולמשוך לקוחות חדשים בעוצמה שלא הכרת.
</p>
        </div>
    </div>
  )
}

export default Offer
