import React from 'react'

const Featurebox = (props) => {
  return (
    <div className='a-box'>
        <div className='a-b-img'>
            <img src={props.image} alt='Feature logo' />
        </div>
        <div className='a-b-text'>
            <h3 dir='rtl'> {props.title} </h3>
            <p dir='rtl'> {props.text} </p>
        </div>
    </div>
  )
}

export default Featurebox
