import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [step, setStep] = useState(1); // State to manage the current form step
  const [errors, setErrors] = useState({});

  const handleNext = (e) => {
    e.preventDefault();
    if (validateStep1()) {
      setStep(2);
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const validateStep1 = () => {
    const newErrors = {};
    if (!email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      newErrors.email = 'אימייל לא תקין';
    }
    if (!phone.match(/^\d{10}$/)) {
      newErrors.phone = 'מספר טלפון לא תקין';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};
    if (message.length < 30) {
      newErrors.message = 'ההודעה חייבת להיות לפחות 30 תווים';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep2()) {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('message', message);

      fetch('https://formspree.io/f/mkgwnqwr', {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      }).then((response) => {
        if (response.ok) {
          alert('Form submitted successfully!');
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
          setStep(1);
        } else {
          alert('Form submission failed!');
        }
      });
    }
  };

  return (
    <div className="contact-container" id='contact'>
      <div className="contact-form-box">
        <h2>צור קשר</h2>
        <div dir='rtl' className="progress-bar">
          <div className="progress" style={{ width: step === 1 ? '50%' : '100%' }}></div>
        </div>
        <form className='contact-form' id='contact-form' dir='rtl' onSubmit={handleSubmit}>
          {step === 1 && (
            <>
              <input 
                type="text" 
                placeholder="השם שלך" 
                className="contact-input" 
                aria-required="true"
                aria-label="Name"
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                required 
              />
              <input 
                type="email" 
                placeholder="אימייל" 
                className="contact-input"
                aria-required="true"
                aria-label="Email"
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                required 
              />
              {errors.email && <p className="error">{errors.email}</p>}
              <input 
                type="tel" 
                placeholder="מספר טלפון" 
                className="contact-input" 
                aria-required="true"
                aria-label="Phone"
                value={phone} 
                onChange={(e) => setPhone(e.target.value)} 
                required 
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
              <button 
                type="button" 
                id='next' 
                className="contact-button" 
                onClick={handleNext}
              >
                המשך
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <textarea 
                placeholder="נשמח אם תפרט על העסק שלך ועל איזה פתרונות שיווק אתה מחפש :)" 
                className="contact-textarea" 
                aria-required="true"
                aria-label="How can we help?"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              {errors.message && <p className="error">{errors.message}</p>}
              <button 
                type="button" 
                id='back' 
                className="contact-button" 
                onClick={handleBack}
              >
                חזור
              </button>
              <button 
                type="submit" 
                id='submit' 
                className="contact-button"
              >
                שלח
              </button>
            </>
          )}
        </form>
      </div>
      <div dir='rtl' className="contact-content">
        <p><span aria-label="Contact us now">צור קשר עכשיו!</span> כדי לדון ביעדים העסקיים שלך. יחד, נבנה את האסטרטגיה המושלמת שתשיג את התוצאות שאתה ראוי להן.</p>
        <hr className="contact-hr" />
        <p>שתף אותנו באתגרים שלך, ואנו נציע פתרונות יצירתיים וחדשניים. תן לנו להראות לך איך שיווק דיגיטלי יכול לשנות את פני העסק שלך ולהביא אותך למקום שאתה חולם עליו. עכשיו זה הזמן לפעול!</p>
      </div>
    </div>
  );
}

export default Contact;
